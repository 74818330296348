/* Styles.css */

/* Common styles for all screen sizes */
.StripeElement {
  display: block;
  margin: 10px 0;
  padding: 10px 12px;
  font-size: 16px;
  font-family: 'Helvetica Neue', Helvetica, sans-serif;
  color: #32325d;
  background-color: white;
  border: 1px solid #aab7c4;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
  color: #fa755a;
}

/* Adjust placeholder color */
.StripeElement::placeholder {
  color: #aab7c4;
}

/* Additional styles for the label */
label {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: bold;
}

/* Additional styles for error messages */
.error-message {
  color: #fa755a;
  margin-top: 8px;
  font-size: 14px;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .StripeElement {
    font-size: 14px;
    padding: 8px 10px;
  }

  label {
    font-size: 12px;
    margin-bottom: 6px; /* Adjust spacing for smaller screens */
  }

  .error-message {
    font-size: 12px;
    margin-top: 6px; /* Adjust spacing for smaller screens */
  }
}
