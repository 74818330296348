.user-card {
    border: 1px solid #ccc;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    display: inline-block;
    width: calc(33% - 20px);
    box-sizing: border-box;
  }
  
  .user-card button {
    margin: 5px;
  }
  